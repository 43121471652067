import * as React from "react";
import { Form, Table } from "react-bootstrap";
import { ActionButton } from "../../clay/ActionButton";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import {
    QuickCacheApi,
    useQuickRecord,
    useQuickRecords,
} from "../../clay/quick-cache";
import { StaticDateTimeWidget } from "../../clay/widgets/DateTimeWidget";
import { DefaultSwitchWidget } from "../../clay/widgets/DefaultSwitchWidget";
import { simpleLinkWidget } from "../../clay/widgets/dropdown-link-widget";
import {
    FormField,
    FormWrapper,
    Optional,
    OptionalFormField,
} from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { FieldRow } from "../../clay/widgets/layout";
import { LinkSetWidget } from "../../clay/widgets/link-set-widget";
import { ListWidget } from "../../clay/widgets/ListWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { hasPermission } from "../../permissions";
import { OPTION_META } from "../estimate/option/table";
import { formatMoney } from "../estimate/TotalsWidget.widget";
import { QUOTATION_META } from "../quotation/table";
import { RichTextWidget } from "../rich-text-widget";
import { useUser } from "../state";
import { TABLE_STYLE } from "../styles";
import CompetitorDetailWidget from "./CompetitorDetailWidget.widget";
import { PreferredCertifiedForemenWidget } from "./preferred-certified-foreman/widget";
import {
    ProjectLostAwardShared,
    SELECTED_QUOTATION_WIDGET,
} from "./projectLostAwardShared";
import { ReactContext as ProjectWidgetReactContext } from "./ProjectWidget.widget";
import { SeasonWidget } from "./SeasonWidget";
import {
    calcProjectLienHoldbackRequiredDefault,
    Project,
    PROJECT_META,
} from "./table";
import {
    AnticipatedDurationLinkWidget,
    ApprovalTypeLinkWidget,
} from "./types/link";
import { ANTICIPATED_CREW_SIZE_META, APPROVAL_TYPE_META } from "./types/table";

export type Data = Project;

export const Fields = {
    selectedQuotations: SELECTED_QUOTATION_WIDGET,
    projectAwardDate: StaticDateTimeWidget,
    customerPurchaseOrderNumber: FormField(TextWidget),
    competitors: ListWidget(CompetitorDetailWidget, { emptyOk: true }),
    approvalType: FormField(ApprovalTypeLinkWidget),
    seasons: FormField(SeasonWidget),
    anticipatedDuration: FormField(AnticipatedDurationLinkWidget),
    contractAwardSpecialNeedsAndNotes: OptionalFormField(RichTextWidget),
    anticipatedCrewSize: OptionalFormField(
        simpleLinkWidget(ANTICIPATED_CREW_SIZE_META)
    ),
    preferredCertifiedForemen: PreferredCertifiedForemenWidget,
    selectedOptions: Optional(
        LinkSetWidget({
            meta: OPTION_META,
            name: (option) => option.name,
        })
    ),
    lienHoldbackRequiredOverride: FormField(DefaultSwitchWidget),
    // Make sure any fields in this widget are reset in the handler for
    // CANCEL_AWARD_LOST at the project level
};

function validate(data: Project, cache: QuickCacheApi) {
    const errors = baseValidate(data, cache);

    const approvalType = cache.get(APPROVAL_TYPE_META, data.approvalType);
    if (approvalType) {
        return errors.filter((detail) => {
            switch (detail.field) {
                case "customerPurchaseOrderNumber":
                    return approvalType.requireCustomerPO;
                default:
                    return true;
            }
        });
    }

    return errors;
}

function Component(props: Props) {
    const projectContext = React.useContext(ProjectWidgetReactContext)!;

    const approvalType = useQuickRecord(
        APPROVAL_TYPE_META,
        props.data.approvalType
    );
    const quotations = useQuickRecords(
        QUOTATION_META,
        props.data.selectedQuotations as string[]
    );
    const user = useUser();
    return (
        <>
            <ProjectLostAwardShared data={props.data} widgets={widgets} />
            <Table
                {...TABLE_STYLE}
                style={{
                    width: 'max-content'
                }}
            >
                <thead>
                    <tr>
                        <th style={{ textAlign: "left" }}>Remdal Option</th>
                        <th style={{ textAlign: "left" }}>
                            Project Description
                        </th>
                        <th style={{ textAlign: "right" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {quotations
                        .flatMap((x) => x.options)
                        .map((option) => (
                            <tr key={option.id.uuid}>
                                <td>
                                    <Form.Check
                                        style={{
                                            display: "inline-block",
                                        }}
                                        className="checkbox-widget"
                                        type="checkbox"
                                        checked={
                                            props.data.selectedOptions.indexOf(
                                                option.id.uuid
                                            ) !== -1
                                        }
                                        disabled={
                                            !props.status.mutable ||
                                            props.data.contractDetailsDate !==
                                                null
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            props.dispatch({
                                                type: "SELECTED_OPTIONS",
                                                action: {
                                                    type: "SET",
                                                    value: event.target.checked
                                                        ? [
                                                              ...props.data
                                                                  .selectedOptions,
                                                              option.id.uuid,
                                                          ]
                                                        : props.data.selectedOptions.filter(
                                                              (entry) =>
                                                                  entry !==
                                                                  option.id.uuid
                                                          ),
                                                },
                                            })
                                        }
                                    />{" "}
                                    {option.name}
                                </td>
                                <td>{option.description}</td>
                                <td style={{ textAlign: "right" }}>
                                    {formatMoney(option.details.total)}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <FieldRow noExpand>
                <FormWrapper label="Contract Award Date">
                    <div style={{ display: "flex" }}>
                        <widgets.projectAwardDate />
                        <div style={{ width: "1em" }} />
                        <ActionButton
                            status={props.status}
                            disabled={props.data.contractDetailsDate !== null}
                            onClick={() =>
                                projectContext.dispatch({
                                    type: "CANCEL_AWARD_LOST",
                                })
                            }
                        >
                            Cancel
                        </ActionButton>
                    </div>
                </FormWrapper>
                <widgets.lienHoldbackRequiredOverride
                    defaultValue={calcProjectLienHoldbackRequiredDefault(
                        props.data
                    )}
                    free={
                        !calcProjectLienHoldbackRequiredDefault(props.data) ||
                        hasPermission(
                            user,
                            "Project",
                            "override-lien-holdback-required"
                        )
                    }
                    label="Lien Holdback Required"
                    style={{ alignSelf: "center" }}
                />
            </FieldRow>

            <FieldRow>
                <widgets.approvalType label="Type of Approval" />
                <widgets.customerPurchaseOrderNumber label="Customer PO #" />
            </FieldRow>
            <FieldRow>
                <widgets.seasons label="Work Season" />
                <widgets.anticipatedDuration />
            </FieldRow>
            <FieldRow>
                <widgets.anticipatedCrewSize />
            </FieldRow>
            <widgets.contractAwardSpecialNeedsAndNotes label="Contract Notes and Special Needs" />


            <widgets.preferredCertifiedForemen />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.selectedQuotations> &
    WidgetContext<typeof Fields.projectAwardDate> &
    WidgetContext<typeof Fields.customerPurchaseOrderNumber> &
    WidgetContext<typeof Fields.competitors> &
    WidgetContext<typeof Fields.approvalType> &
    WidgetContext<typeof Fields.seasons> &
    WidgetContext<typeof Fields.anticipatedDuration> &
    WidgetContext<typeof Fields.contractAwardSpecialNeedsAndNotes> &
    WidgetContext<typeof Fields.anticipatedCrewSize> &
    WidgetContext<typeof Fields.preferredCertifiedForemen> &
    WidgetContext<typeof Fields.selectedOptions> &
    WidgetContext<typeof Fields.lienHoldbackRequiredOverride>;
type ExtraProps = {};
type BaseState = {
    selectedQuotations: WidgetState<typeof Fields.selectedQuotations>;
    projectAwardDate: WidgetState<typeof Fields.projectAwardDate>;
    customerPurchaseOrderNumber: WidgetState<
        typeof Fields.customerPurchaseOrderNumber
    >;
    competitors: WidgetState<typeof Fields.competitors>;
    approvalType: WidgetState<typeof Fields.approvalType>;
    seasons: WidgetState<typeof Fields.seasons>;
    anticipatedDuration: WidgetState<typeof Fields.anticipatedDuration>;
    contractAwardSpecialNeedsAndNotes: WidgetState<
        typeof Fields.contractAwardSpecialNeedsAndNotes
    >;
    anticipatedCrewSize: WidgetState<typeof Fields.anticipatedCrewSize>;
    preferredCertifiedForemen: WidgetState<
        typeof Fields.preferredCertifiedForemen
    >;
    selectedOptions: WidgetState<typeof Fields.selectedOptions>;
    lienHoldbackRequiredOverride: WidgetState<
        typeof Fields.lienHoldbackRequiredOverride
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "SELECTED_QUOTATIONS";
          action: WidgetAction<typeof Fields.selectedQuotations>;
      }
    | {
          type: "PROJECT_AWARD_DATE";
          action: WidgetAction<typeof Fields.projectAwardDate>;
      }
    | {
          type: "CUSTOMER_PURCHASE_ORDER_NUMBER";
          action: WidgetAction<typeof Fields.customerPurchaseOrderNumber>;
      }
    | { type: "COMPETITORS"; action: WidgetAction<typeof Fields.competitors> }
    | {
          type: "APPROVAL_TYPE";
          action: WidgetAction<typeof Fields.approvalType>;
      }
    | { type: "SEASONS"; action: WidgetAction<typeof Fields.seasons> }
    | {
          type: "ANTICIPATED_DURATION";
          action: WidgetAction<typeof Fields.anticipatedDuration>;
      }
    | {
          type: "CONTRACT_AWARD_SPECIAL_NEEDS_AND_NOTES";
          action: WidgetAction<typeof Fields.contractAwardSpecialNeedsAndNotes>;
      }
    | {
          type: "ANTICIPATED_CREW_SIZE";
          action: WidgetAction<typeof Fields.anticipatedCrewSize>;
      }
    | {
          type: "PREFERRED_CERTIFIED_FOREMEN";
          action: WidgetAction<typeof Fields.preferredCertifiedForemen>;
      }
    | {
          type: "SELECTED_OPTIONS";
          action: WidgetAction<typeof Fields.selectedOptions>;
      }
    | {
          type: "LIEN_HOLDBACK_REQUIRED_OVERRIDE";
          action: WidgetAction<typeof Fields.lienHoldbackRequiredOverride>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.selectedQuotations,
        data.selectedQuotations,
        cache,
        "selectedQuotations",
        errors
    );
    subvalidate(
        Fields.projectAwardDate,
        data.projectAwardDate,
        cache,
        "projectAwardDate",
        errors
    );
    subvalidate(
        Fields.customerPurchaseOrderNumber,
        data.customerPurchaseOrderNumber,
        cache,
        "customerPurchaseOrderNumber",
        errors
    );
    subvalidate(
        Fields.competitors,
        data.competitors,
        cache,
        "competitors",
        errors
    );
    subvalidate(
        Fields.approvalType,
        data.approvalType,
        cache,
        "approvalType",
        errors
    );
    subvalidate(Fields.seasons, data.seasons, cache, "seasons", errors);
    subvalidate(
        Fields.anticipatedDuration,
        data.anticipatedDuration,
        cache,
        "anticipatedDuration",
        errors
    );
    subvalidate(
        Fields.contractAwardSpecialNeedsAndNotes,
        data.contractAwardSpecialNeedsAndNotes,
        cache,
        "contractAwardSpecialNeedsAndNotes",
        errors
    );
    subvalidate(
        Fields.anticipatedCrewSize,
        data.anticipatedCrewSize,
        cache,
        "anticipatedCrewSize",
        errors
    );
    subvalidate(
        Fields.preferredCertifiedForemen,
        data.preferredCertifiedForemen,
        cache,
        "preferredCertifiedForemen",
        errors
    );
    subvalidate(
        Fields.selectedOptions,
        data.selectedOptions,
        cache,
        "selectedOptions",
        errors
    );
    subvalidate(
        Fields.lienHoldbackRequiredOverride,
        data.lienHoldbackRequiredOverride,
        cache,
        "lienHoldbackRequiredOverride",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "SELECTED_QUOTATIONS": {
            const inner = Fields.selectedQuotations.reduce(
                state.selectedQuotations,
                data.selectedQuotations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, selectedQuotations: inner.state },
                data: { ...data, selectedQuotations: inner.data },
            };
        }
        case "PROJECT_AWARD_DATE": {
            const inner = Fields.projectAwardDate.reduce(
                state.projectAwardDate,
                data.projectAwardDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectAwardDate: inner.state },
                data: { ...data, projectAwardDate: inner.data },
            };
        }
        case "CUSTOMER_PURCHASE_ORDER_NUMBER": {
            const inner = Fields.customerPurchaseOrderNumber.reduce(
                state.customerPurchaseOrderNumber,
                data.customerPurchaseOrderNumber,
                action.action,
                subcontext
            );
            return {
                state: { ...state, customerPurchaseOrderNumber: inner.state },
                data: { ...data, customerPurchaseOrderNumber: inner.data },
            };
        }
        case "COMPETITORS": {
            const inner = Fields.competitors.reduce(
                state.competitors,
                data.competitors,
                action.action,
                subcontext
            );
            return {
                state: { ...state, competitors: inner.state },
                data: { ...data, competitors: inner.data },
            };
        }
        case "APPROVAL_TYPE": {
            const inner = Fields.approvalType.reduce(
                state.approvalType,
                data.approvalType,
                action.action,
                subcontext
            );
            return {
                state: { ...state, approvalType: inner.state },
                data: { ...data, approvalType: inner.data },
            };
        }
        case "SEASONS": {
            const inner = Fields.seasons.reduce(
                state.seasons,
                data.seasons,
                action.action,
                subcontext
            );
            return {
                state: { ...state, seasons: inner.state },
                data: { ...data, seasons: inner.data },
            };
        }
        case "ANTICIPATED_DURATION": {
            const inner = Fields.anticipatedDuration.reduce(
                state.anticipatedDuration,
                data.anticipatedDuration,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedDuration: inner.state },
                data: { ...data, anticipatedDuration: inner.data },
            };
        }
        case "CONTRACT_AWARD_SPECIAL_NEEDS_AND_NOTES": {
            const inner = Fields.contractAwardSpecialNeedsAndNotes.reduce(
                state.contractAwardSpecialNeedsAndNotes,
                data.contractAwardSpecialNeedsAndNotes,
                action.action,
                subcontext
            );
            return {
                state: {
                    ...state,
                    contractAwardSpecialNeedsAndNotes: inner.state,
                },
                data: {
                    ...data,
                    contractAwardSpecialNeedsAndNotes: inner.data,
                },
            };
        }
        case "ANTICIPATED_CREW_SIZE": {
            const inner = Fields.anticipatedCrewSize.reduce(
                state.anticipatedCrewSize,
                data.anticipatedCrewSize,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedCrewSize: inner.state },
                data: { ...data, anticipatedCrewSize: inner.data },
            };
        }
        case "PREFERRED_CERTIFIED_FOREMEN": {
            const inner = Fields.preferredCertifiedForemen.reduce(
                state.preferredCertifiedForemen,
                data.preferredCertifiedForemen,
                action.action,
                subcontext
            );
            return {
                state: { ...state, preferredCertifiedForemen: inner.state },
                data: { ...data, preferredCertifiedForemen: inner.data },
            };
        }
        case "SELECTED_OPTIONS": {
            const inner = Fields.selectedOptions.reduce(
                state.selectedOptions,
                data.selectedOptions,
                action.action,
                subcontext
            );
            return {
                state: { ...state, selectedOptions: inner.state },
                data: { ...data, selectedOptions: inner.data },
            };
        }
        case "LIEN_HOLDBACK_REQUIRED_OVERRIDE": {
            const inner = Fields.lienHoldbackRequiredOverride.reduce(
                state.lienHoldbackRequiredOverride,
                data.lienHoldbackRequiredOverride,
                action.action,
                subcontext
            );
            return {
                state: { ...state, lienHoldbackRequiredOverride: inner.state },
                data: { ...data, lienHoldbackRequiredOverride: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    selectedQuotations: function (
        props: WidgetExtraProps<typeof Fields.selectedQuotations> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SELECTED_QUOTATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "selectedQuotations",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.selectedQuotations.component
                state={context.state.selectedQuotations}
                data={context.data.selectedQuotations}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Selected Quotations"}
            />
        );
    },
    projectAwardDate: function (
        props: WidgetExtraProps<typeof Fields.projectAwardDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_AWARD_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "projectAwardDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectAwardDate.component
                state={context.state.projectAwardDate}
                data={context.data.projectAwardDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Award Date"}
            />
        );
    },
    customerPurchaseOrderNumber: function (
        props: WidgetExtraProps<typeof Fields.customerPurchaseOrderNumber> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CUSTOMER_PURCHASE_ORDER_NUMBER",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "customerPurchaseOrderNumber",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.customerPurchaseOrderNumber.component
                state={context.state.customerPurchaseOrderNumber}
                data={context.data.customerPurchaseOrderNumber}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Customer Purchase Order Number"}
            />
        );
    },
    competitors: function (
        props: WidgetExtraProps<typeof Fields.competitors> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "COMPETITORS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "competitors", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.competitors.component
                state={context.state.competitors}
                data={context.data.competitors}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Competitors"}
            />
        );
    },
    approvalType: function (
        props: WidgetExtraProps<typeof Fields.approvalType> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "APPROVAL_TYPE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "approvalType", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.approvalType.component
                state={context.state.approvalType}
                data={context.data.approvalType}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Approval Type"}
            />
        );
    },
    seasons: function (
        props: WidgetExtraProps<typeof Fields.seasons> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SEASONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "seasons", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.seasons.component
                state={context.state.seasons}
                data={context.data.seasons}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Seasons"}
            />
        );
    },
    anticipatedDuration: function (
        props: WidgetExtraProps<typeof Fields.anticipatedDuration> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_DURATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedDuration",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedDuration.component
                state={context.state.anticipatedDuration}
                data={context.data.anticipatedDuration}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Duration"}
            />
        );
    },
    contractAwardSpecialNeedsAndNotes: function (
        props: WidgetExtraProps<
            typeof Fields.contractAwardSpecialNeedsAndNotes
        > & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CONTRACT_AWARD_SPECIAL_NEEDS_AND_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "contractAwardSpecialNeedsAndNotes",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.contractAwardSpecialNeedsAndNotes.component
                state={context.state.contractAwardSpecialNeedsAndNotes}
                data={context.data.contractAwardSpecialNeedsAndNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Contract Award Special Needs and Notes"}
            />
        );
    },
    anticipatedCrewSize: function (
        props: WidgetExtraProps<typeof Fields.anticipatedCrewSize> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_CREW_SIZE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedCrewSize",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedCrewSize.component
                state={context.state.anticipatedCrewSize}
                data={context.data.anticipatedCrewSize}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Crew Size"}
            />
        );
    },
    preferredCertifiedForemen: function (
        props: WidgetExtraProps<typeof Fields.preferredCertifiedForemen> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PREFERRED_CERTIFIED_FOREMEN",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "preferredCertifiedForemen",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.preferredCertifiedForemen.component
                state={context.state.preferredCertifiedForemen}
                data={context.data.preferredCertifiedForemen}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Preferred Certified Foremen"}
            />
        );
    },
    selectedOptions: function (
        props: WidgetExtraProps<typeof Fields.selectedOptions> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SELECTED_OPTIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "selectedOptions", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.selectedOptions.component
                state={context.state.selectedOptions}
                data={context.data.selectedOptions}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Selected Options"}
            />
        );
    },
    lienHoldbackRequiredOverride: function (
        props: WidgetExtraProps<typeof Fields.lienHoldbackRequiredOverride> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LIEN_HOLDBACK_REQUIRED_OVERRIDE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "lienHoldbackRequiredOverride",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.lienHoldbackRequiredOverride.component
                state={context.state.lienHoldbackRequiredOverride}
                data={context.data.lienHoldbackRequiredOverride}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Lien Holdback Required Override"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let selectedQuotationsState;
        {
            const inner = Fields.selectedQuotations.initialize(
                data.selectedQuotations,
                subcontext,
                subparameters.selectedQuotations
            );
            selectedQuotationsState = inner.state;
            data = { ...data, selectedQuotations: inner.data };
        }
        let projectAwardDateState;
        {
            const inner = Fields.projectAwardDate.initialize(
                data.projectAwardDate,
                subcontext,
                subparameters.projectAwardDate
            );
            projectAwardDateState = inner.state;
            data = { ...data, projectAwardDate: inner.data };
        }
        let customerPurchaseOrderNumberState;
        {
            const inner = Fields.customerPurchaseOrderNumber.initialize(
                data.customerPurchaseOrderNumber,
                subcontext,
                subparameters.customerPurchaseOrderNumber
            );
            customerPurchaseOrderNumberState = inner.state;
            data = { ...data, customerPurchaseOrderNumber: inner.data };
        }
        let competitorsState;
        {
            const inner = Fields.competitors.initialize(
                data.competitors,
                subcontext,
                subparameters.competitors
            );
            competitorsState = inner.state;
            data = { ...data, competitors: inner.data };
        }
        let approvalTypeState;
        {
            const inner = Fields.approvalType.initialize(
                data.approvalType,
                subcontext,
                subparameters.approvalType
            );
            approvalTypeState = inner.state;
            data = { ...data, approvalType: inner.data };
        }
        let seasonsState;
        {
            const inner = Fields.seasons.initialize(
                data.seasons,
                subcontext,
                subparameters.seasons
            );
            seasonsState = inner.state;
            data = { ...data, seasons: inner.data };
        }
        let anticipatedDurationState;
        {
            const inner = Fields.anticipatedDuration.initialize(
                data.anticipatedDuration,
                subcontext,
                subparameters.anticipatedDuration
            );
            anticipatedDurationState = inner.state;
            data = { ...data, anticipatedDuration: inner.data };
        }
        let contractAwardSpecialNeedsAndNotesState;
        {
            const inner = Fields.contractAwardSpecialNeedsAndNotes.initialize(
                data.contractAwardSpecialNeedsAndNotes,
                subcontext,
                subparameters.contractAwardSpecialNeedsAndNotes
            );
            contractAwardSpecialNeedsAndNotesState = inner.state;
            data = { ...data, contractAwardSpecialNeedsAndNotes: inner.data };
        }
        let anticipatedCrewSizeState;
        {
            const inner = Fields.anticipatedCrewSize.initialize(
                data.anticipatedCrewSize,
                subcontext,
                subparameters.anticipatedCrewSize
            );
            anticipatedCrewSizeState = inner.state;
            data = { ...data, anticipatedCrewSize: inner.data };
        }
        let preferredCertifiedForemenState;
        {
            const inner = Fields.preferredCertifiedForemen.initialize(
                data.preferredCertifiedForemen,
                subcontext,
                subparameters.preferredCertifiedForemen
            );
            preferredCertifiedForemenState = inner.state;
            data = { ...data, preferredCertifiedForemen: inner.data };
        }
        let selectedOptionsState;
        {
            const inner = Fields.selectedOptions.initialize(
                data.selectedOptions,
                subcontext,
                subparameters.selectedOptions
            );
            selectedOptionsState = inner.state;
            data = { ...data, selectedOptions: inner.data };
        }
        let lienHoldbackRequiredOverrideState;
        {
            const inner = Fields.lienHoldbackRequiredOverride.initialize(
                data.lienHoldbackRequiredOverride,
                subcontext,
                subparameters.lienHoldbackRequiredOverride
            );
            lienHoldbackRequiredOverrideState = inner.state;
            data = { ...data, lienHoldbackRequiredOverride: inner.data };
        }
        let state = {
            initialParameters: parameters,
            selectedQuotations: selectedQuotationsState,
            projectAwardDate: projectAwardDateState,
            customerPurchaseOrderNumber: customerPurchaseOrderNumberState,
            competitors: competitorsState,
            approvalType: approvalTypeState,
            seasons: seasonsState,
            anticipatedDuration: anticipatedDurationState,
            contractAwardSpecialNeedsAndNotes:
                contractAwardSpecialNeedsAndNotesState,
            anticipatedCrewSize: anticipatedCrewSizeState,
            preferredCertifiedForemen: preferredCertifiedForemenState,
            selectedOptions: selectedOptionsState,
            lienHoldbackRequiredOverride: lienHoldbackRequiredOverrideState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    selectedQuotations: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.selectedQuotations>
    >;
    projectAwardDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectAwardDate>
    >;
    customerPurchaseOrderNumber: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.customerPurchaseOrderNumber>
    >;
    competitors: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.competitors>
    >;
    approvalType: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.approvalType>
    >;
    seasons: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.seasons>
    >;
    anticipatedDuration: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedDuration>
    >;
    contractAwardSpecialNeedsAndNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.contractAwardSpecialNeedsAndNotes>
    >;
    anticipatedCrewSize: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedCrewSize>
    >;
    preferredCertifiedForemen: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.preferredCertifiedForemen>
    >;
    selectedOptions: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.selectedOptions>
    >;
    lienHoldbackRequiredOverride: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.lienHoldbackRequiredOverride>
    >;
};
// END MAGIC -- DO NOT EDIT
