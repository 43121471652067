import { some } from "lodash";
import React from "react";
import { Dictionary, Money } from "../../clay/common";
import { Link } from "../../clay/link";
import { propCheck } from "../../clay/propCheck";
import { sumMap } from "../../clay/queryFuncs";
import { QuickCacheApi } from "../../clay/quick-cache";
import { DefaultTextWidget } from "../../clay/widgets/DefaultTextWidget";
import { Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    useRecordContext,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { useListItemContext } from "../../clay/widgets/ListWidget";
import { MoneyStatic, MoneyWidget } from "../../clay/widgets/money-widget";
import { BaseTableRow } from "../../clay/widgets/TableRow";
import { TextAreaWidget } from "../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { encodeBillingItemNumber } from "../contact/spectrum";
import { Invoice } from "../invoice/table";
import { DETAIL_SHEET_META } from "./detail-sheet/table";
import { ProjectSchedule, PROJECT_SCHEDULE_META } from "./schedule";

export type Data = ProjectSchedule;

export const Fields = {
    name: Optional(TextWidget),
    description: TextAreaWidget,
    price: Optional(MoneyWidget),
    certifiedForemanContractAmount: Optional(MoneyWidget),
    groupCode: DefaultTextWidget,
    billingItem: DefaultTextWidget,
};

export type ExtraProps = {
    invoices?: Invoice[];
    lines: {
        amount: Money;
        schedule: Link<ProjectSchedule>;
        nonCfExpense: boolean;
    }[];
    scheduleOffset: number;
};

function validate(data: Data, cache: QuickCacheApi) {
    const errors = baseValidate(data, cache);
    if (
        data.description.indexOf(" as per scope ") !== -1 ||
        data.description.length > 70
    ) {
        errors.push({ field: "description", invalid: true, empty: false });
    }
    return errors;
}

function Component(props: Props) {
    const detailSheet = useRecordContext(DETAIL_SHEET_META);

    const isInvoiced =
        !props.invoices ||
        some(props.invoices, (invoice) =>
            some(
                invoice.options,
                (schedule) =>
                    schedule.id.uuid === props.data.id.uuid &&
                    !schedule.completed.isZero()
            )
        );

    const listItemContext = useListItemContext();

    return (
        <BaseTableRow disableDelete={isInvoiced}>
            <td>
                <widgets.description />
            </td>
            <td>
                <widgets.groupCode defaultText="10" hideStatus />
            </td>
            <td>
                <widgets.billingItem
                    defaultText={
                        listItemContext.index === undefined
                            ? ""
                            : encodeBillingItemNumber(
                                  listItemContext.index +
                                      props.scheduleOffset +
                                      1
                              )
                    }
                    hideStatus
                />
            </td>
            <td>
                <widgets.price readOnly={isInvoiced} />
            </td>
            <td>
                <widgets.certifiedForemanContractAmount
                    readOnly={detailSheet.revamped}
                />
            </td>
            {detailSheet.revamped && (
                <td>
                    <MoneyStatic
                        value={sumMap(
                            props.lines.filter(
                                (x) =>
                                    x.schedule === props.data.id.uuid &&
                                    x.nonCfExpense
                            ),
                            (x) => x.amount
                        )}
                    />
                </td>
            )}
        </BaseTableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.name> &
    WidgetContext<typeof Fields.description> &
    WidgetContext<typeof Fields.price> &
    WidgetContext<typeof Fields.certifiedForemanContractAmount> &
    WidgetContext<typeof Fields.groupCode> &
    WidgetContext<typeof Fields.billingItem>;
type BaseState = {
    name: WidgetState<typeof Fields.name>;
    description: WidgetState<typeof Fields.description>;
    price: WidgetState<typeof Fields.price>;
    certifiedForemanContractAmount: WidgetState<
        typeof Fields.certifiedForemanContractAmount
    >;
    groupCode: WidgetState<typeof Fields.groupCode>;
    billingItem: WidgetState<typeof Fields.billingItem>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "NAME"; action: WidgetAction<typeof Fields.name> }
    | { type: "DESCRIPTION"; action: WidgetAction<typeof Fields.description> }
    | { type: "PRICE"; action: WidgetAction<typeof Fields.price> }
    | {
          type: "CERTIFIED_FOREMAN_CONTRACT_AMOUNT";
          action: WidgetAction<typeof Fields.certifiedForemanContractAmount>;
      }
    | { type: "GROUP_CODE"; action: WidgetAction<typeof Fields.groupCode> }
    | { type: "BILLING_ITEM"; action: WidgetAction<typeof Fields.billingItem> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps> & ExtraProps;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.name, data.name, cache, "name", errors);
    subvalidate(
        Fields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(Fields.price, data.price, cache, "price", errors);
    subvalidate(
        Fields.certifiedForemanContractAmount,
        data.certifiedForemanContractAmount,
        cache,
        "certifiedForemanContractAmount",
        errors
    );
    subvalidate(Fields.groupCode, data.groupCode, cache, "groupCode", errors);
    subvalidate(
        Fields.billingItem,
        data.billingItem,
        cache,
        "billingItem",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = Fields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = Fields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "PRICE": {
            const inner = Fields.price.reduce(
                state.price,
                data.price,
                action.action,
                subcontext
            );
            return {
                state: { ...state, price: inner.state },
                data: { ...data, price: inner.data },
            };
        }
        case "CERTIFIED_FOREMAN_CONTRACT_AMOUNT": {
            const inner = Fields.certifiedForemanContractAmount.reduce(
                state.certifiedForemanContractAmount,
                data.certifiedForemanContractAmount,
                action.action,
                subcontext
            );
            return {
                state: {
                    ...state,
                    certifiedForemanContractAmount: inner.state,
                },
                data: { ...data, certifiedForemanContractAmount: inner.data },
            };
        }
        case "GROUP_CODE": {
            const inner = Fields.groupCode.reduce(
                state.groupCode,
                data.groupCode,
                action.action,
                subcontext
            );
            return {
                state: { ...state, groupCode: inner.state },
                data: { ...data, groupCode: inner.data },
            };
        }
        case "BILLING_ITEM": {
            const inner = Fields.billingItem.reduce(
                state.billingItem,
                data.billingItem,
                action.action,
                subcontext
            );
            return {
                state: { ...state, billingItem: inner.state },
                data: { ...data, billingItem: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    name: function (
        props: WidgetExtraProps<typeof Fields.name> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NAME", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.name.component
                state={context.state.name}
                data={context.data.name}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Name"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof Fields.description> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.description.component
                state={context.state.description}
                data={context.data.description}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Description"}
            />
        );
    },
    price: function (
        props: WidgetExtraProps<typeof Fields.price> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "PRICE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "price", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.price.component
                state={context.state.price}
                data={context.data.price}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Price"}
            />
        );
    },
    certifiedForemanContractAmount: function (
        props: WidgetExtraProps<
            typeof Fields.certifiedForemanContractAmount
        > & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CERTIFIED_FOREMAN_CONTRACT_AMOUNT",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "certifiedForemanContractAmount",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.certifiedForemanContractAmount.component
                state={context.state.certifiedForemanContractAmount}
                data={context.data.certifiedForemanContractAmount}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Certified Foreman Contract Amount"}
            />
        );
    },
    groupCode: function (
        props: WidgetExtraProps<typeof Fields.groupCode> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "GROUP_CODE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "groupCode", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.groupCode.component
                state={context.state.groupCode}
                data={context.data.groupCode}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Group Code"}
            />
        );
    },
    billingItem: function (
        props: WidgetExtraProps<typeof Fields.billingItem> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "BILLING_ITEM",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "billingItem", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.billingItem.component
                state={context.state.billingItem}
                data={context.data.billingItem}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Billing Item"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_SCHEDULE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let nameState;
        {
            const inner = Fields.name.initialize(
                data.name,
                subcontext,
                subparameters.name
            );
            nameState = inner.state;
            data = { ...data, name: inner.data };
        }
        let descriptionState;
        {
            const inner = Fields.description.initialize(
                data.description,
                subcontext,
                subparameters.description
            );
            descriptionState = inner.state;
            data = { ...data, description: inner.data };
        }
        let priceState;
        {
            const inner = Fields.price.initialize(
                data.price,
                subcontext,
                subparameters.price
            );
            priceState = inner.state;
            data = { ...data, price: inner.data };
        }
        let certifiedForemanContractAmountState;
        {
            const inner = Fields.certifiedForemanContractAmount.initialize(
                data.certifiedForemanContractAmount,
                subcontext,
                subparameters.certifiedForemanContractAmount
            );
            certifiedForemanContractAmountState = inner.state;
            data = { ...data, certifiedForemanContractAmount: inner.data };
        }
        let groupCodeState;
        {
            const inner = Fields.groupCode.initialize(
                data.groupCode,
                subcontext,
                subparameters.groupCode
            );
            groupCodeState = inner.state;
            data = { ...data, groupCode: inner.data };
        }
        let billingItemState;
        {
            const inner = Fields.billingItem.initialize(
                data.billingItem,
                subcontext,
                subparameters.billingItem
            );
            billingItemState = inner.state;
            data = { ...data, billingItem: inner.data };
        }
        let state = {
            initialParameters: parameters,
            name: nameState,
            description: descriptionState,
            price: priceState,
            certifiedForemanContractAmount: certifiedForemanContractAmountState,
            groupCode: groupCodeState,
            billingItem: billingItemState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_SCHEDULE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    name: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.name>
    >;
    description: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.description>
    >;
    price: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.price>
    >;
    certifiedForemanContractAmount: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.certifiedForemanContractAmount>
    >;
    groupCode: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.groupCode>
    >;
    billingItem: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.billingItem>
    >;
};
// END MAGIC -- DO NOT EDIT
