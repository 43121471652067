import { Button } from "react-bootstrap";
import { daysAgo, ifNull, lastItem } from "../../clay/queryFuncs";
import { FormWrapper } from "../../clay/widgets/FormField";
import { StaticPhoneWidget } from "../../clay/widgets/phone";
import { StaticTextField } from "../../clay/widgets/TextWidget";
import { calcAddressLineFormatted } from "../address";
import RFQToQWidget from "../project/ProjectRfqToQWidget.widget";
import {
    calcProjectContactReminderDueDate,
    calcProjectSummary,
    PROJECT_META,
} from "../project/table";
import { MessageBody, MessageFooter, MessageHeader } from "./message";
import NotificationSource from "./NotificationSource";
import { ITEM_TYPE } from "./types";
import { useRecordWidget } from "./useRecordWidget";

import Decimal from "decimal.js";
import LockedWidget from "../project/locked/LockedWidget.widget";
import * as React from "react";

function Component(props: {
    id: string;
    setOpenItem: (item: ITEM_TYPE | null) => void;
}) {
    const {
        data: project,
        component,
        isValid,
        onSave,
    } = useRecordWidget(RFQToQWidget, props.id, props.setOpenItem);

    if (!project) {
        return <></>;
    }

    const contact = lastItem(project.contactAttempts, (x) => x.contact)!;
    console.log(contact);
    return (
        <>
            <MessageHeader>
                {calcProjectSummary(project)} Contact Your Site Contact
            </MessageHeader>
            <MessageBody>
                <FormWrapper label="Client's Name">
                    <StaticTextField value={project.customer} />
                </FormWrapper>
                <FormWrapper label="Site Address">
                    <StaticTextField
                        value={calcAddressLineFormatted(project.siteAddress)}
                    />
                </FormWrapper>
                <FormWrapper label="Contact Name">
                    <StaticTextField value={contact.name} />
                </FormWrapper>
                {contact.phones.map((phone, index) => (
                    <FormWrapper
                        label={`Contact Phone (${phone.type})`}
                        key={index}
                    >
                        <StaticPhoneWidget data={phone.number} />
                    </FormWrapper>
                ))}
                <FormWrapper label="Contact Email">
                    <StaticTextField value={contact.email} />
                </FormWrapper>
                <FormWrapper label="Client's Request">
                    <LockedWidget.component
                        data={project.customersRequest}
                        status={{
                            mutable: false,
                            validation: [],
                        }}
                        dispatch={() => {}}
                        state={{ value: false }}
                    />

                    {component}
                </FormWrapper>
            </MessageBody>
            <MessageFooter>
                <Button
                    onClick={() => {
                        window.open("#/project/edit/" + props.id + "/");
                        props.setOpenItem(null);
                    }}
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Open Project
                </Button>
                <Button
                    style={{ marginLeft: "1em", display: "block" }}
                    disabled={!isValid}
                    onClick={onSave}
                >
                    Save
                </Button>
            </MessageFooter>
        </>
    );
}

export const CONTACT_REMINDER_SOURCE = NotificationSource({
    key: "contact-reminder-scheduled",
    label: "Contact Your Site Contact",
    Component,
    table: PROJECT_META,
    date: calcProjectContactReminderDueDate,
    dated: true,
    sendToUser(record) {
        return ifNull(
            ifNull(
                daysAgo(calcProjectContactReminderDueDate(record)),
                new Decimal("-1")
            ).gt("-1"),
            false
        )!
            ? lastItem(record.contactAttempts, (x) => x.user)
            : null;
    },
});
