import { isNull } from "lodash";
import * as React from "react";
import { Alert, Button } from "react-bootstrap";
import { patchRecord, useDraftProjectRecordQuery } from "../../clay/api";
import { exportTable } from "../project/accounting/ExportDataTable";
import { DETAIL_SHEET_META } from "../project/detail-sheet/table";
import { useScheduleOfValuesTable } from "../project/overview/ScheduleOfValuesExportWidget.widget";
import { usePhaseCodeExport } from "../project/overview/SpectrumExportWidget.widget";
import ProjectAccountingWidget from "../project/ProjectAccountingWidget.widget";
import {
    calcProjectSummary,
    JSONToProject,
    PROJECT_META,
    repairProjectJSON,
} from "../project/table";
import { useUser } from "../state";
import { MessageBody, MessageFooter, MessageHeader } from "./message";
import NotificationSource from "./NotificationSource";
import { ITEM_TYPE } from "./types";
import { useRecordWidget } from "./useRecordWidget";

function Component(props: {
    id: string;
    setOpenItem: (item: ITEM_TYPE | null) => void;
}) {
    const { component, data, isValid } = useRecordWidget(
        ProjectAccountingWidget,
        props.id,
        props.setOpenItem,
        false
    );

    const detailSheets = useDraftProjectRecordQuery(
        DETAIL_SHEET_META,
        props.id
    );

    const scheduleOfValues = useScheduleOfValuesTable(
        {
            data: data || JSONToProject(repairProjectJSON({ id: props.id })),
        },
        false
    );

    const exportSchedules = React.useCallback(() => {
        exportTable({
            ...scheduleOfValues,
            name: `Spectrum Export Schedule of Values ${
                data!.projectNumber
            } - ${new Date().toString()}.xlsx`,
        });
    }, [scheduleOfValues, data]);

    const phaseCodes = usePhaseCodeExport(props.id, false);

    const exportPhaseCodes = React.useCallback(() => {
        exportTable({
            ...phaseCodes,
            name: `Spectrum Export Phase Codes ${
                data!.projectNumber
            } - ${new Date().toString()}.xlsx`,
        });
    }, [phaseCodes, data]);

    const user = useUser();

    const onSave = React.useCallback(async () => {
        if (!data || !detailSheets) {
            return;
        }
        for (const detailSheet of detailSheets) {
            if (
                detailSheet.date &&
                !detailSheet.addedToAccountingSoftware.date
            ) {
                await patchRecord(
                    DETAIL_SHEET_META,
                    "inbox",
                    detailSheet.id.uuid,
                    {
                        addedToAccountingSoftware: [
                            detailSheet.addedToAccountingSoftware,
                            {
                                date: new Date(),
                                user: user.id,
                            },
                        ],
                    }
                );
            }
        }
        if (data.addedToAccountingSoftware.date == null) {
            await patchRecord(PROJECT_META, "inbox", props.id, {
                addedToAccountingSoftware: [
                    data.addedToAccountingSoftware,
                    {
                        date: new Date(),
                        user: user.id,
                    },
                ],
            });
        } else {
            await patchRecord(PROJECT_META, "inbox", props.id, {});
        }

        props.setOpenItem(null);
    }, [detailSheets, data, user, props.id]);

    if (!data || !detailSheets) {
        return <></>;
    }

    return (
        <>
            <MessageHeader>
                {calcProjectSummary(data)} Add Project To Accounting
            </MessageHeader>
            <MessageBody>{component}</MessageBody>
            <MessageFooter>
                {detailSheets && (
                    <Alert variant="info">
                        {
                            detailSheets.filter(
                                (sheet) =>
                                    sheet.date !== null &&
                                    sheet.addedToAccountingSoftware.date ===
                                        null
                            ).length
                        }{" "}
                        Detail Sheets Need to be Added
                    </Alert>
                )}
                <Button
                    onClick={() => {
                        window.open(
                            "#/project/edit/" +
                                props.id +
                                "/accounting/accounting/"
                        );
                        props.setOpenItem(null);
                    }}
                    style={{ display: "block", marginLeft: "auto" }}
                >
                    Open Project
                </Button>
                <Button
                    onClick={exportSchedules}
                    style={{ marginLeft: "1em", display: "block" }}
                >
                    Export Schedules
                </Button>
                <Button
                    onClick={exportPhaseCodes}
                    style={{ marginLeft: "1em", display: "block" }}
                >
                    Export Phase Codes
                </Button>
                <Button
                    style={{ marginLeft: "1em", display: "block" }}
                    disabled={!isValid}
                    onClick={onSave}
                >
                    Mark As Added
                </Button>
            </MessageFooter>
        </>
    );
}

export const UNADDED_TO_ACCOUNTING_SOURCE = NotificationSource({
    key: "unadded-to-accounting",
    label(project) {
        return isNull(project.addedToAccountingSoftware.date)
            ? "PROJECT TO BE ADDED TO SPECTRUM"
            : "CHANGE ORDER TO BE ADDED TO SPECTRUM";
    },
    Component,
    table: PROJECT_META,
    active: "hasDetailSheetsUnaddedToAccounting",
    sendToUsersWithPermission: "Inbox-show-unadded-to-accounting",
    date: (project) => project.contractDetailsDate,
});
