import { ContactDetail } from "../contact/table";
import { Project } from "../project/table";
import { ROLE_PROJECT_MANAGER, User } from "../user/table";

export type EmailContact = ContactDetail & {
    source: "contact" | "billing" | "remdal";
};

export type TemplateData = {
    project: Project;
    contacts: EmailContact[];
    users: User[];
};

type ClientCommunicationVariable = (props: TemplateData) => string;

export const ClientCommunicationVariables: Record<
    string,
    ClientCommunicationVariable
> = {
    ClientName({ project }) {
        return project.customer;
    },
    ContactName({ contacts }) {
        return contacts
            .filter((x) => x.source !== "remdal")
            .map((contact) => contact.name)
            .join(", ");
    },
    ProjectManagerName({ project, users }) {
        return project.personnel
            .filter((x) => x.role === ROLE_PROJECT_MANAGER)
            .map((x) => users.find((user) => user.id.uuid == x.user)?.name)
            .join(", ");
    },
};

export function resolveTemplateText(text: string, data: TemplateData) {
    return text.replace(/{{([^}]*)}}/g, (_, match) => {
        return ClientCommunicationVariables[match]?.(data) || "";
    });
}
