import React from "react";
import { Alert, Button, ButtonGroup } from "react-bootstrap";
import { rawRequest } from "../../clay/api";
import { useEditableContext } from "../../clay/edit-context";
import { hasPermission } from "../../permissions";
import type { ExportTypes } from "../../server/export";
import { useUser } from "../state";

export type ExportData<template extends keyof ExportTypes> = {
    warnings: string[];
    errors: string[];
    request: ExportTypes[template];
};

export function NewExportButton<template extends keyof ExportTypes>(props: {
    template: template;
    data: ExportData<template>;
}) {
    const [spectrumErrors, setSpectrumErrors] = React.useState([]);
    const editable = useEditableContext();
    const user = useUser();

    async function exportToSpectrum() {
        editable.save!.onClick(null, undefined, true);

        const res = await rawRequest({
            type: "EXPORT",
            template: props.template,
            request: props.data.request,
        });
        if (res.errors.length == 0) {
            window.alert("Exported");
        } else {
            setSpectrumErrors(res.errors);
        }
    }

    if (!hasPermission(user, "General", "export-spectrum")) {
        return <></>;
    }

    return (
        <>
            {editable.dirty && (
                <Alert variant="danger">Please Save Before Exporting</Alert>
            )}
            {spectrumErrors.map((x) => (
                <Alert variant="danger">{x}</Alert>
            ))}
            {props.data.errors.map((x) => (
                <Alert variant="danger">{x}</Alert>
            ))}
            {props.data.warnings.map((x) => (
                <Alert variant="warning">{x}</Alert>
            ))}
            <ButtonGroup>
                <Button onClick={exportToSpectrum} disabled={editable.dirty}>
                    Export to Spectrum
                </Button>
            </ButtonGroup>
        </>
    );
}
