import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "react-bootstrap";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { StaticDateTimeWidget } from "../../clay/widgets/DateTimeWidget";
import { DateWidget } from "../../clay/widgets/DateWidget";
import { Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    useRecordContext,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { SelectWidget } from "../../clay/widgets/SelectWidget";
import { BaseTableRow } from "../../clay/widgets/TableRow";
import { SelectContactWidget } from "../contact/select-contact-widget";
import { RichTextWidget } from "../rich-text-widget";
import { UserLinkWidget } from "../user";
import { ContactAttempt, CONTACT_ATTEMPT_META, PROJECT_META } from "./table";

export type Data = ContactAttempt;

export const Fields = {
    addedDateTime: StaticDateTimeWidget,
    reminderDate: Optional(DateWidget),
    user: UserLinkWidget,
    contact: SelectContactWidget,
    type: SelectWidget([
        { value: "email", label: "Email" },
        { value: "phone", label: "Phone call" },
        { value: "sms", label: "Text message" },
    ]),
    notes: RichTextWidget,
};

type ExtraProps = {
    remove: () => void;
};

function Component(props: Props) {
    const project = useRecordContext(PROJECT_META);

    return (
        <>
            <BaseTableRow disableMove>
                <th>Contact Attempted</th>
                <td>
                    <widgets.user readOnly={true} />
                </td>
                <td>
                    <widgets.addedDateTime />
                </td>
                <td>
                    <widgets.type />
                </td>
                <td>
                    <widgets.contact
                        contacts={[
                            ...project.billingContacts,
                            ...project.contacts,
                        ]}
                    />
                </td>
                <td>
                    <widgets.reminderDate />
                </td>
                <td>
                    <Button
                        variant="danger"
                        onClick={props.remove}
                        disabled={!props.status.mutable}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </td>
            </BaseTableRow>
            <tr>
                <td colSpan={5}>
                    <widgets.notes />
                </td>
            </tr>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.addedDateTime> &
    WidgetContext<typeof Fields.reminderDate> &
    WidgetContext<typeof Fields.user> &
    WidgetContext<typeof Fields.contact> &
    WidgetContext<typeof Fields.type> &
    WidgetContext<typeof Fields.notes>;
type BaseState = {
    addedDateTime: WidgetState<typeof Fields.addedDateTime>;
    reminderDate: WidgetState<typeof Fields.reminderDate>;
    user: WidgetState<typeof Fields.user>;
    contact: WidgetState<typeof Fields.contact>;
    type: WidgetState<typeof Fields.type>;
    notes: WidgetState<typeof Fields.notes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "ADDED_DATE_TIME";
          action: WidgetAction<typeof Fields.addedDateTime>;
      }
    | {
          type: "REMINDER_DATE";
          action: WidgetAction<typeof Fields.reminderDate>;
      }
    | { type: "USER"; action: WidgetAction<typeof Fields.user> }
    | { type: "CONTACT"; action: WidgetAction<typeof Fields.contact> }
    | { type: "TYPE"; action: WidgetAction<typeof Fields.type> }
    | { type: "NOTES"; action: WidgetAction<typeof Fields.notes> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps> & ExtraProps;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.addedDateTime,
        data.addedDateTime,
        cache,
        "addedDateTime",
        errors
    );
    subvalidate(
        Fields.reminderDate,
        data.reminderDate,
        cache,
        "reminderDate",
        errors
    );
    subvalidate(Fields.user, data.user, cache, "user", errors);
    subvalidate(Fields.contact, data.contact, cache, "contact", errors);
    subvalidate(Fields.type, data.type, cache, "type", errors);
    subvalidate(Fields.notes, data.notes, cache, "notes", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ADDED_DATE_TIME": {
            const inner = Fields.addedDateTime.reduce(
                state.addedDateTime,
                data.addedDateTime,
                action.action,
                subcontext
            );
            return {
                state: { ...state, addedDateTime: inner.state },
                data: { ...data, addedDateTime: inner.data },
            };
        }
        case "REMINDER_DATE": {
            const inner = Fields.reminderDate.reduce(
                state.reminderDate,
                data.reminderDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, reminderDate: inner.state },
                data: { ...data, reminderDate: inner.data },
            };
        }
        case "USER": {
            const inner = Fields.user.reduce(
                state.user,
                data.user,
                action.action,
                subcontext
            );
            return {
                state: { ...state, user: inner.state },
                data: { ...data, user: inner.data },
            };
        }
        case "CONTACT": {
            const inner = Fields.contact.reduce(
                state.contact,
                data.contact,
                action.action,
                subcontext
            );
            return {
                state: { ...state, contact: inner.state },
                data: { ...data, contact: inner.data },
            };
        }
        case "TYPE": {
            const inner = Fields.type.reduce(
                state.type,
                data.type,
                action.action,
                subcontext
            );
            return {
                state: { ...state, type: inner.state },
                data: { ...data, type: inner.data },
            };
        }
        case "NOTES": {
            const inner = Fields.notes.reduce(
                state.notes,
                data.notes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, notes: inner.state },
                data: { ...data, notes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    addedDateTime: function (
        props: WidgetExtraProps<typeof Fields.addedDateTime> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ADDED_DATE_TIME",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "addedDateTime", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.addedDateTime.component
                state={context.state.addedDateTime}
                data={context.data.addedDateTime}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Added Date Time"}
            />
        );
    },
    reminderDate: function (
        props: WidgetExtraProps<typeof Fields.reminderDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REMINDER_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "reminderDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.reminderDate.component
                state={context.state.reminderDate}
                data={context.data.reminderDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Reminder Date"}
            />
        );
    },
    user: function (
        props: WidgetExtraProps<typeof Fields.user> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "USER", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "user", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.user.component
                state={context.state.user}
                data={context.data.user}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "User"}
            />
        );
    },
    contact: function (
        props: WidgetExtraProps<typeof Fields.contact> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CONTACT",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "contact", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.contact.component
                state={context.state.contact}
                data={context.data.contact}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Contact"}
            />
        );
    },
    type: function (
        props: WidgetExtraProps<typeof Fields.type> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "TYPE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "type", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.type.component
                state={context.state.type}
                data={context.data.type}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Type"}
            />
        );
    },
    notes: function (
        props: WidgetExtraProps<typeof Fields.notes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NOTES", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "notes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.notes.component
                state={context.state.notes}
                data={context.data.notes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: CONTACT_ATTEMPT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let addedDateTimeState;
        {
            const inner = Fields.addedDateTime.initialize(
                data.addedDateTime,
                subcontext,
                subparameters.addedDateTime
            );
            addedDateTimeState = inner.state;
            data = { ...data, addedDateTime: inner.data };
        }
        let reminderDateState;
        {
            const inner = Fields.reminderDate.initialize(
                data.reminderDate,
                subcontext,
                subparameters.reminderDate
            );
            reminderDateState = inner.state;
            data = { ...data, reminderDate: inner.data };
        }
        let userState;
        {
            const inner = Fields.user.initialize(
                data.user,
                subcontext,
                subparameters.user
            );
            userState = inner.state;
            data = { ...data, user: inner.data };
        }
        let contactState;
        {
            const inner = Fields.contact.initialize(
                data.contact,
                subcontext,
                subparameters.contact
            );
            contactState = inner.state;
            data = { ...data, contact: inner.data };
        }
        let typeState;
        {
            const inner = Fields.type.initialize(
                data.type,
                subcontext,
                subparameters.type
            );
            typeState = inner.state;
            data = { ...data, type: inner.data };
        }
        let notesState;
        {
            const inner = Fields.notes.initialize(
                data.notes,
                subcontext,
                subparameters.notes
            );
            notesState = inner.state;
            data = { ...data, notes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            addedDateTime: addedDateTimeState,
            reminderDate: reminderDateState,
            user: userState,
            contact: contactState,
            type: typeState,
            notes: notesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={CONTACT_ATTEMPT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    addedDateTime: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.addedDateTime>
    >;
    reminderDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.reminderDate>
    >;
    user: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.user>
    >;
    contact: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.contact>
    >;
    type: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.type>
    >;
    notes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.notes>
    >;
};
// END MAGIC -- DO NOT EDIT
