import { QuickCacheApi } from "../../clay/quick-cache";
import { UUID } from "../../clay/uuid";
import { ValidationError } from "../../clay/widgets";

export function validateSpectrumCustomerCode(
    data: {
        spectrumCustomerCode: string;
        id: UUID;
    },
    cache: QuickCacheApi,
    errors: ValidationError[]
) {
    if (
        data.spectrumCustomerCode != "" &&
        (data.spectrumCustomerCode.length != 6 ||
            cache.exists(
                "spectrum-company-customer-code-" +
                    data.spectrumCustomerCode +
                    data.id.uuid,
                {
                    tableName: "Company",
                    columns: [],
                    filters: [
                        {
                            column: "spectrumCustomerCode",
                            filter: {
                                equal: data.spectrumCustomerCode,
                            },
                        },
                        {
                            column: "id",
                            filter: {
                                not_equal: data.id.uuid,
                            },
                        },
                    ],
                }
            ) ||
            cache.exists(
                "spectrum-contact-customer-code-" +
                    data.spectrumCustomerCode +
                    data.id.uuid,
                {
                    tableName: "Contact",
                    columns: [],
                    filters: [
                        {
                            column: "spectrumCustomerCode",
                            filter: {
                                equal: data.spectrumCustomerCode,
                            },
                        },
                        {
                            column: "id",
                            filter: {
                                not_equal: data.id.uuid,
                            },
                        },
                    ],
                }
            ))
    ) {
        errors.push({
            field: "spectrumCustomerCode",
            empty: false,
            invalid: true,
        });
    }
}

export function encodeBillingItemNumber(x: number, groupCode: string = "10") {
    return groupCode + `${x}`.padStart(2, "0") + "0";
}
