import { User } from "@sentry/react";
import dateParse from "date-fns/parseISO";
import { Decimal } from "decimal.js";
import { Project } from "ts-morph";
import { Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { LocalDate } from "../../../clay/LocalDate";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import {
    ContactDetail,
    ContactDetailJSON,
    ContactDetailToJSON,
    CONTACT_DETAIL_META,
    JSONToContactDetail,
    repairContactDetailJSON,
} from "../../contact/table";
import {
    JSONToNoteList,
    NoteList,
    NoteListJSON,
    NoteListToJSON,
    NOTE_LIST_META,
    repairNoteListJSON,
} from "../../quotation/notes/table";
import {
    JSONToRoleWithPercentage,
    Quotation,
    repairRoleWithPercentageJSON,
    RoleWithPercentage,
    RoleWithPercentageJSON,
    RoleWithPercentageToJSON,
    ROLE_WITH_PERCENTAGE_META,
} from "../../quotation/table";
import {
    DetailSheetFinishSchedule,
    DetailSheetFinishScheduleJSON,
    DetailSheetFinishScheduleToJSON,
    DetailSheetRequiredEquipment,
    DetailSheetRequiredEquipmentJSON,
    DetailSheetRequiredEquipmentToJSON,
    DETAIL_SHEET_FINISH_SCHEDULE_META,
    DETAIL_SHEET_REQUIRED_EQUIPMENT_META,
    JSONToDetailSheetFinishSchedule,
    JSONToDetailSheetRequiredEquipment,
    repairDetailSheetFinishScheduleJSON,
    repairDetailSheetRequiredEquipmentJSON,
} from "../detail-sheet/table";
import {
    AnticipatedCrewSize,
    AnticipatedDuration,
    AvailableWorkingDays,
    MockupExpectation,
    ParkingType,
    RequiredNoticeType,
} from "../types/table";

//!Data
export type MockupExpectationLine = {
    id: UUID;
    type: Link<MockupExpectation>;
    note: string;
    due: LocalDate | null;
};

//!Data
export type HandoffNoteDescription = {
    id: UUID;
    text: string;
};

//!Data
export type HandoffNote = {
    id: UUID;
    recordVersion: Version;
    project: Link<Project>;
    firstDate: Date | null;
    date: Date | null;
    addedDateTime: Date | null;
    addedBy: Link<User>;
    modifiedDateTime: Date | null;
    certifiedForemen: Link<User>[];
    certifiedForeman: Link<User>;
    number: Quantity;

    initialized: boolean;

    specifierContacts: ContactDetail[];
    contacts: ContactDetail[];
    quotations: Link<Quotation>[];
    managers: RoleWithPercentage[];

    projectedStartDate: LocalDate | null;
    projectedCompletionDate: LocalDate | null;
    anticipatedDuration: Link<AnticipatedDuration>;
    availableWorkingDays: Link<AvailableWorkingDays>;
    anticipatedCrewSize: Link<AnticipatedCrewSize>;
    mockupExpectations: MockupExpectationLine[];

    projectPhases: string[];
    preexistingConditions: string[];
    potentialForUnforeseenWork: string[];

    scopeOfWork: NoteList[];

    finishSchedule: DetailSheetFinishSchedule[];
    contractNotes: NoteList[];

    clientSuccessCommunications: string;
    writtenOrVerbalPromises: string;

    requiredEquipmentList: DetailSheetRequiredEquipment[];
    challengingLocations: string;

    requiredNotices: Link<RequiredNoticeType>[];
    siteStorage: string;
    liftParkingRequired: boolean;
    liftOvernightParkingLocation: string;
    portableToiletRequired: boolean;
    portableToiletProposedLocation: string;
    locationOfOnSiteWashrooms: string;
    parking: Link<ParkingType>;
    parkingNotes: string;

    potentialDelays: string;
    colourApprovalProcess: string;
    permitsRequired: boolean;
    permitsRequiredNote: string;
    potentialBudgetChallenges: string;

    description: HandoffNoteDescription[];
    applicationInstructions: string;
    clientSuccessGeneralNotes: string;
    accessGeneralNotes: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type MockupExpectationLineJSON = {
    id: string;
    type: string | null;
    note: string;
    due: string | null;
};

export function JSONToMockupExpectationLine(
    json: MockupExpectationLineJSON
): MockupExpectationLine {
    return {
        id: { uuid: json.id },
        type: json.type,
        note: json.note,
        due: json.due !== null ? LocalDate.parse(json.due) : null,
    };
}
export type MockupExpectationLineBrokenJSON = {
    id?: string;
    type?: string | null;
    note?: string;
    due?: string | null;
};

export function newMockupExpectationLine(): MockupExpectationLine {
    return JSONToMockupExpectationLine(
        repairMockupExpectationLineJSON(undefined)
    );
}
export function repairMockupExpectationLineJSON(
    json: MockupExpectationLineBrokenJSON | undefined
): MockupExpectationLineJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            type: json.type || null,
            note: json.note || "",
            due: json.due || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            type: undefined || null,
            note: undefined || "",
            due: undefined || null,
        };
    }
}

export function MockupExpectationLineToJSON(
    value: MockupExpectationLine
): MockupExpectationLineJSON {
    return {
        id: value.id.uuid,
        type: value.type,
        note: value.note,
        due: value.due !== null ? value.due.toString() : null,
    };
}

export const MOCKUP_EXPECTATION_LINE_META: RecordMeta<
    MockupExpectationLine,
    MockupExpectationLineJSON,
    MockupExpectationLineBrokenJSON
> & { name: "MockupExpectationLine" } = {
    name: "MockupExpectationLine",
    type: "record",
    repair: repairMockupExpectationLineJSON,
    toJSON: MockupExpectationLineToJSON,
    fromJSON: JSONToMockupExpectationLine,
    fields: {
        id: { type: "uuid" },
        type: { type: "uuid", linkTo: "MockupExpectation" },
        note: { type: "string" },
        due: { type: "date" },
    },
    userFacingKey: null,
    functions: {},
    segments: {},
};

export type HandoffNoteDescriptionJSON = {
    id: string;
    text: string;
};

export function JSONToHandoffNoteDescription(
    json: HandoffNoteDescriptionJSON
): HandoffNoteDescription {
    return {
        id: { uuid: json.id },
        text: json.text,
    };
}
export type HandoffNoteDescriptionBrokenJSON = {
    id?: string;
    text?: string;
};

export function newHandoffNoteDescription(): HandoffNoteDescription {
    return JSONToHandoffNoteDescription(
        repairHandoffNoteDescriptionJSON(undefined)
    );
}
export function repairHandoffNoteDescriptionJSON(
    json: HandoffNoteDescriptionBrokenJSON | undefined
): HandoffNoteDescriptionJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            text: json.text || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            text: undefined || "",
        };
    }
}

export function HandoffNoteDescriptionToJSON(
    value: HandoffNoteDescription
): HandoffNoteDescriptionJSON {
    return {
        id: value.id.uuid,
        text: value.text,
    };
}

export const HANDOFF_NOTE_DESCRIPTION_META: RecordMeta<
    HandoffNoteDescription,
    HandoffNoteDescriptionJSON,
    HandoffNoteDescriptionBrokenJSON
> & { name: "HandoffNoteDescription" } = {
    name: "HandoffNoteDescription",
    type: "record",
    repair: repairHandoffNoteDescriptionJSON,
    toJSON: HandoffNoteDescriptionToJSON,
    fromJSON: JSONToHandoffNoteDescription,
    fields: {
        id: { type: "uuid" },
        text: { type: "string" },
    },
    userFacingKey: null,
    functions: {},
    segments: {},
};

export type HandoffNoteJSON = {
    id: string;
    recordVersion: number | null;
    project: string | null;
    firstDate: string | null;
    date: string | null;
    addedDateTime: string | null;
    addedBy: string | null;
    modifiedDateTime: string | null;
    certifiedForemen: (string | null)[];
    certifiedForeman: string | null;
    number: string;
    initialized: boolean;
    specifierContacts: ContactDetailJSON[];
    contacts: ContactDetailJSON[];
    quotations: (string | null)[];
    managers: RoleWithPercentageJSON[];
    projectedStartDate: string | null;
    projectedCompletionDate: string | null;
    anticipatedDuration: string | null;
    availableWorkingDays: string | null;
    anticipatedCrewSize: string | null;
    mockupExpectations: MockupExpectationLineJSON[];
    projectPhases: string[];
    preexistingConditions: string[];
    potentialForUnforeseenWork: string[];
    scopeOfWork: NoteListJSON[];
    finishSchedule: DetailSheetFinishScheduleJSON[];
    contractNotes: NoteListJSON[];
    clientSuccessCommunications: string;
    writtenOrVerbalPromises: string;
    requiredEquipmentList: DetailSheetRequiredEquipmentJSON[];
    challengingLocations: string;
    requiredNotices: (string | null)[];
    siteStorage: string;
    liftParkingRequired: boolean;
    liftOvernightParkingLocation: string;
    portableToiletRequired: boolean;
    portableToiletProposedLocation: string;
    locationOfOnSiteWashrooms: string;
    parking: string | null;
    parkingNotes: string;
    potentialDelays: string;
    colourApprovalProcess: string;
    permitsRequired: boolean;
    permitsRequiredNote: string;
    potentialBudgetChallenges: string;
    description: HandoffNoteDescriptionJSON[];
    applicationInstructions: string;
    clientSuccessGeneralNotes: string;
    accessGeneralNotes: string;
};

export function JSONToHandoffNote(json: HandoffNoteJSON): HandoffNote {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        project: json.project,
        firstDate: json.firstDate !== null ? dateParse(json.firstDate) : null,
        date: json.date !== null ? dateParse(json.date) : null,
        addedDateTime:
            json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
        addedBy: json.addedBy,
        modifiedDateTime:
            json.modifiedDateTime !== null
                ? dateParse(json.modifiedDateTime)
                : null,
        certifiedForemen: json.certifiedForemen.map((inner) => inner),
        certifiedForeman: json.certifiedForeman,
        number: new Decimal(json.number),
        initialized: json.initialized,
        specifierContacts: json.specifierContacts.map((inner) =>
            JSONToContactDetail(inner)
        ),
        contacts: json.contacts.map((inner) => JSONToContactDetail(inner)),
        quotations: json.quotations.map((inner) => inner),
        managers: json.managers.map((inner) => JSONToRoleWithPercentage(inner)),
        projectedStartDate:
            json.projectedStartDate !== null
                ? LocalDate.parse(json.projectedStartDate)
                : null,
        projectedCompletionDate:
            json.projectedCompletionDate !== null
                ? LocalDate.parse(json.projectedCompletionDate)
                : null,
        anticipatedDuration: json.anticipatedDuration,
        availableWorkingDays: json.availableWorkingDays,
        anticipatedCrewSize: json.anticipatedCrewSize,
        mockupExpectations: json.mockupExpectations.map((inner) =>
            JSONToMockupExpectationLine(inner)
        ),
        projectPhases: json.projectPhases.map((inner) => inner),
        preexistingConditions: json.preexistingConditions.map((inner) => inner),
        potentialForUnforeseenWork: json.potentialForUnforeseenWork.map(
            (inner) => inner
        ),
        scopeOfWork: json.scopeOfWork.map((inner) => JSONToNoteList(inner)),
        finishSchedule: json.finishSchedule.map((inner) =>
            JSONToDetailSheetFinishSchedule(inner)
        ),
        contractNotes: json.contractNotes.map((inner) => JSONToNoteList(inner)),
        clientSuccessCommunications: json.clientSuccessCommunications,
        writtenOrVerbalPromises: json.writtenOrVerbalPromises,
        requiredEquipmentList: json.requiredEquipmentList.map((inner) =>
            JSONToDetailSheetRequiredEquipment(inner)
        ),
        challengingLocations: json.challengingLocations,
        requiredNotices: json.requiredNotices.map((inner) => inner),
        siteStorage: json.siteStorage,
        liftParkingRequired: json.liftParkingRequired,
        liftOvernightParkingLocation: json.liftOvernightParkingLocation,
        portableToiletRequired: json.portableToiletRequired,
        portableToiletProposedLocation: json.portableToiletProposedLocation,
        locationOfOnSiteWashrooms: json.locationOfOnSiteWashrooms,
        parking: json.parking,
        parkingNotes: json.parkingNotes,
        potentialDelays: json.potentialDelays,
        colourApprovalProcess: json.colourApprovalProcess,
        permitsRequired: json.permitsRequired,
        permitsRequiredNote: json.permitsRequiredNote,
        potentialBudgetChallenges: json.potentialBudgetChallenges,
        description: json.description.map((inner) =>
            JSONToHandoffNoteDescription(inner)
        ),
        applicationInstructions: json.applicationInstructions,
        clientSuccessGeneralNotes: json.clientSuccessGeneralNotes,
        accessGeneralNotes: json.accessGeneralNotes,
    };
}
export type HandoffNoteBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    project?: string | null;
    firstDate?: string | null;
    date?: string | null;
    addedDateTime?: string | null;
    addedBy?: string | null;
    modifiedDateTime?: string | null;
    certifiedForemen?: (string | null)[];
    certifiedForeman?: string | null;
    number?: string;
    initialized?: boolean;
    specifierContacts?: ContactDetailJSON[];
    contacts?: ContactDetailJSON[];
    quotations?: (string | null)[];
    managers?: RoleWithPercentageJSON[];
    projectedStartDate?: string | null;
    projectedCompletionDate?: string | null;
    anticipatedDuration?: string | null;
    availableWorkingDays?: string | null;
    anticipatedCrewSize?: string | null;
    mockupExpectations?: MockupExpectationLineJSON[];
    projectPhases?: string[];
    preexistingConditions?: string[];
    potentialForUnforeseenWork?: string[];
    scopeOfWork?: NoteListJSON[];
    finishSchedule?: DetailSheetFinishScheduleJSON[];
    contractNotes?: NoteListJSON[];
    clientSuccessCommunications?: string;
    writtenOrVerbalPromises?: string;
    requiredEquipmentList?: DetailSheetRequiredEquipmentJSON[];
    challengingLocations?: string;
    requiredNotices?: (string | null)[];
    siteStorage?: string;
    liftParkingRequired?: boolean;
    liftOvernightParkingLocation?: string;
    portableToiletRequired?: boolean;
    portableToiletProposedLocation?: string;
    locationOfOnSiteWashrooms?: string;
    parking?: string | null;
    parkingNotes?: string;
    potentialDelays?: string;
    colourApprovalProcess?: string;
    permitsRequired?: boolean;
    permitsRequiredNote?: string;
    potentialBudgetChallenges?: string;
    description?: HandoffNoteDescriptionJSON[];
    applicationInstructions?: string;
    clientSuccessGeneralNotes?: string;
    accessGeneralNotes?: string;
};

export function newHandoffNote(): HandoffNote {
    return JSONToHandoffNote(repairHandoffNoteJSON(undefined));
}
export function repairHandoffNoteJSON(
    json: HandoffNoteBrokenJSON | undefined
): HandoffNoteJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            project: json.project || null,
            firstDate: json.firstDate
                ? new Date(json.firstDate!).toISOString()
                : null,
            date: json.date ? new Date(json.date!).toISOString() : null,
            addedDateTime: json.addedDateTime
                ? new Date(json.addedDateTime!).toISOString()
                : null,
            addedBy: json.addedBy || null,
            modifiedDateTime: json.modifiedDateTime
                ? new Date(json.modifiedDateTime!).toISOString()
                : null,
            certifiedForemen: (json.certifiedForemen || []).map(
                (inner) => inner || null
            ),
            certifiedForeman: json.certifiedForeman || null,
            number: json.number || "0",
            initialized: json.initialized || false,
            specifierContacts: (json.specifierContacts || []).map((inner) =>
                repairContactDetailJSON(inner)
            ),
            contacts: (json.contacts || []).map((inner) =>
                repairContactDetailJSON(inner)
            ),
            quotations: (json.quotations || []).map((inner) => inner || null),
            managers: (json.managers || []).map((inner) =>
                repairRoleWithPercentageJSON(inner)
            ),
            projectedStartDate: json.projectedStartDate || null,
            projectedCompletionDate: json.projectedCompletionDate || null,
            anticipatedDuration: json.anticipatedDuration || null,
            availableWorkingDays: json.availableWorkingDays || null,
            anticipatedCrewSize: json.anticipatedCrewSize || null,
            mockupExpectations: (json.mockupExpectations || []).map((inner) =>
                repairMockupExpectationLineJSON(inner)
            ),
            projectPhases: (json.projectPhases || []).map(
                (inner) => inner || ""
            ),
            preexistingConditions: (json.preexistingConditions || []).map(
                (inner) => inner || ""
            ),
            potentialForUnforeseenWork: (
                json.potentialForUnforeseenWork || []
            ).map((inner) => inner || ""),
            scopeOfWork: (json.scopeOfWork || []).map((inner) =>
                repairNoteListJSON(inner)
            ),
            finishSchedule: (json.finishSchedule || []).map((inner) =>
                repairDetailSheetFinishScheduleJSON(inner)
            ),
            contractNotes: (json.contractNotes || []).map((inner) =>
                repairNoteListJSON(inner)
            ),
            clientSuccessCommunications: json.clientSuccessCommunications || "",
            writtenOrVerbalPromises: json.writtenOrVerbalPromises || "",
            requiredEquipmentList: (json.requiredEquipmentList || []).map(
                (inner) => repairDetailSheetRequiredEquipmentJSON(inner)
            ),
            challengingLocations: json.challengingLocations || "",
            requiredNotices: (json.requiredNotices || []).map(
                (inner) => inner || null
            ),
            siteStorage: json.siteStorage || "",
            liftParkingRequired: json.liftParkingRequired || false,
            liftOvernightParkingLocation:
                json.liftOvernightParkingLocation || "",
            portableToiletRequired: json.portableToiletRequired || false,
            portableToiletProposedLocation:
                json.portableToiletProposedLocation || "",
            locationOfOnSiteWashrooms: json.locationOfOnSiteWashrooms || "",
            parking: json.parking || null,
            parkingNotes: json.parkingNotes || "",
            potentialDelays: json.potentialDelays || "",
            colourApprovalProcess: json.colourApprovalProcess || "",
            permitsRequired: json.permitsRequired || false,
            permitsRequiredNote: json.permitsRequiredNote || "",
            potentialBudgetChallenges: json.potentialBudgetChallenges || "",
            description: (json.description || []).map((inner) =>
                repairHandoffNoteDescriptionJSON(inner)
            ),
            applicationInstructions: json.applicationInstructions || "",
            clientSuccessGeneralNotes: json.clientSuccessGeneralNotes || "",
            accessGeneralNotes: json.accessGeneralNotes || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            project: undefined || null,
            firstDate: undefined ? new Date(undefined!).toISOString() : null,
            date: undefined ? new Date(undefined!).toISOString() : null,
            addedDateTime: undefined
                ? new Date(undefined!).toISOString()
                : null,
            addedBy: undefined || null,
            modifiedDateTime: undefined
                ? new Date(undefined!).toISOString()
                : null,
            certifiedForemen: (undefined || []).map((inner) => inner || null),
            certifiedForeman: undefined || null,
            number: undefined || "0",
            initialized: undefined || false,
            specifierContacts: (undefined || []).map((inner) =>
                repairContactDetailJSON(inner)
            ),
            contacts: (undefined || []).map((inner) =>
                repairContactDetailJSON(inner)
            ),
            quotations: (undefined || []).map((inner) => inner || null),
            managers: (undefined || []).map((inner) =>
                repairRoleWithPercentageJSON(inner)
            ),
            projectedStartDate: undefined || null,
            projectedCompletionDate: undefined || null,
            anticipatedDuration: undefined || null,
            availableWorkingDays: undefined || null,
            anticipatedCrewSize: undefined || null,
            mockupExpectations: (undefined || []).map((inner) =>
                repairMockupExpectationLineJSON(inner)
            ),
            projectPhases: (undefined || []).map((inner) => inner || ""),
            preexistingConditions: (undefined || []).map(
                (inner) => inner || ""
            ),
            potentialForUnforeseenWork: (undefined || []).map(
                (inner) => inner || ""
            ),
            scopeOfWork: (undefined || []).map((inner) =>
                repairNoteListJSON(inner)
            ),
            finishSchedule: (undefined || []).map((inner) =>
                repairDetailSheetFinishScheduleJSON(inner)
            ),
            contractNotes: (undefined || []).map((inner) =>
                repairNoteListJSON(inner)
            ),
            clientSuccessCommunications: undefined || "",
            writtenOrVerbalPromises: undefined || "",
            requiredEquipmentList: (undefined || []).map((inner) =>
                repairDetailSheetRequiredEquipmentJSON(inner)
            ),
            challengingLocations: undefined || "",
            requiredNotices: (undefined || []).map((inner) => inner || null),
            siteStorage: undefined || "",
            liftParkingRequired: undefined || false,
            liftOvernightParkingLocation: undefined || "",
            portableToiletRequired: undefined || false,
            portableToiletProposedLocation: undefined || "",
            locationOfOnSiteWashrooms: undefined || "",
            parking: undefined || null,
            parkingNotes: undefined || "",
            potentialDelays: undefined || "",
            colourApprovalProcess: undefined || "",
            permitsRequired: undefined || false,
            permitsRequiredNote: undefined || "",
            potentialBudgetChallenges: undefined || "",
            description: (undefined || []).map((inner) =>
                repairHandoffNoteDescriptionJSON(inner)
            ),
            applicationInstructions: undefined || "",
            clientSuccessGeneralNotes: undefined || "",
            accessGeneralNotes: undefined || "",
        };
    }
}

export function HandoffNoteToJSON(value: HandoffNote): HandoffNoteJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        project: value.project,
        firstDate:
            value.firstDate !== null ? value.firstDate.toISOString() : null,
        date: value.date !== null ? value.date.toISOString() : null,
        addedDateTime:
            value.addedDateTime !== null
                ? value.addedDateTime.toISOString()
                : null,
        addedBy: value.addedBy,
        modifiedDateTime:
            value.modifiedDateTime !== null
                ? value.modifiedDateTime.toISOString()
                : null,
        certifiedForemen: value.certifiedForemen.map((inner) => inner),
        certifiedForeman: value.certifiedForeman,
        number: value.number.toString(),
        initialized: value.initialized,
        specifierContacts: value.specifierContacts.map((inner) =>
            ContactDetailToJSON(inner)
        ),
        contacts: value.contacts.map((inner) => ContactDetailToJSON(inner)),
        quotations: value.quotations.map((inner) => inner),
        managers: value.managers.map((inner) =>
            RoleWithPercentageToJSON(inner)
        ),
        projectedStartDate:
            value.projectedStartDate !== null
                ? value.projectedStartDate.toString()
                : null,
        projectedCompletionDate:
            value.projectedCompletionDate !== null
                ? value.projectedCompletionDate.toString()
                : null,
        anticipatedDuration: value.anticipatedDuration,
        availableWorkingDays: value.availableWorkingDays,
        anticipatedCrewSize: value.anticipatedCrewSize,
        mockupExpectations: value.mockupExpectations.map((inner) =>
            MockupExpectationLineToJSON(inner)
        ),
        projectPhases: value.projectPhases.map((inner) => inner),
        preexistingConditions: value.preexistingConditions.map(
            (inner) => inner
        ),
        potentialForUnforeseenWork: value.potentialForUnforeseenWork.map(
            (inner) => inner
        ),
        scopeOfWork: value.scopeOfWork.map((inner) => NoteListToJSON(inner)),
        finishSchedule: value.finishSchedule.map((inner) =>
            DetailSheetFinishScheduleToJSON(inner)
        ),
        contractNotes: value.contractNotes.map((inner) =>
            NoteListToJSON(inner)
        ),
        clientSuccessCommunications: value.clientSuccessCommunications,
        writtenOrVerbalPromises: value.writtenOrVerbalPromises,
        requiredEquipmentList: value.requiredEquipmentList.map((inner) =>
            DetailSheetRequiredEquipmentToJSON(inner)
        ),
        challengingLocations: value.challengingLocations,
        requiredNotices: value.requiredNotices.map((inner) => inner),
        siteStorage: value.siteStorage,
        liftParkingRequired: value.liftParkingRequired,
        liftOvernightParkingLocation: value.liftOvernightParkingLocation,
        portableToiletRequired: value.portableToiletRequired,
        portableToiletProposedLocation: value.portableToiletProposedLocation,
        locationOfOnSiteWashrooms: value.locationOfOnSiteWashrooms,
        parking: value.parking,
        parkingNotes: value.parkingNotes,
        potentialDelays: value.potentialDelays,
        colourApprovalProcess: value.colourApprovalProcess,
        permitsRequired: value.permitsRequired,
        permitsRequiredNote: value.permitsRequiredNote,
        potentialBudgetChallenges: value.potentialBudgetChallenges,
        description: value.description.map((inner) =>
            HandoffNoteDescriptionToJSON(inner)
        ),
        applicationInstructions: value.applicationInstructions,
        clientSuccessGeneralNotes: value.clientSuccessGeneralNotes,
        accessGeneralNotes: value.accessGeneralNotes,
    };
}

export const HANDOFF_NOTE_META: RecordMeta<
    HandoffNote,
    HandoffNoteJSON,
    HandoffNoteBrokenJSON
> & { name: "HandoffNote" } = {
    name: "HandoffNote",
    type: "record",
    repair: repairHandoffNoteJSON,
    toJSON: HandoffNoteToJSON,
    fromJSON: JSONToHandoffNote,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        project: { type: "uuid", linkTo: "Project" },
        firstDate: { type: "datetime" },
        date: { type: "datetime" },
        addedDateTime: { type: "datetime" },
        addedBy: { type: "uuid", linkTo: "User" },
        modifiedDateTime: { type: "datetime" },
        certifiedForemen: {
            type: "array",
            items: { type: "uuid", linkTo: "User" },
        },
        certifiedForeman: { type: "uuid", linkTo: "User" },
        number: { type: "quantity" },
        initialized: { type: "boolean" },
        specifierContacts: { type: "array", items: CONTACT_DETAIL_META },
        contacts: { type: "array", items: CONTACT_DETAIL_META },
        quotations: {
            type: "array",
            items: { type: "uuid", linkTo: "Quotation" },
        },
        managers: { type: "array", items: ROLE_WITH_PERCENTAGE_META },
        projectedStartDate: { type: "date" },
        projectedCompletionDate: { type: "date" },
        anticipatedDuration: { type: "uuid", linkTo: "AnticipatedDuration" },
        availableWorkingDays: { type: "uuid", linkTo: "AvailableWorkingDays" },
        anticipatedCrewSize: { type: "uuid", linkTo: "AnticipatedCrewSize" },
        mockupExpectations: {
            type: "array",
            items: MOCKUP_EXPECTATION_LINE_META,
        },
        projectPhases: { type: "array", items: { type: "string" } },
        preexistingConditions: { type: "array", items: { type: "string" } },
        potentialForUnforeseenWork: {
            type: "array",
            items: { type: "string" },
        },
        scopeOfWork: { type: "array", items: NOTE_LIST_META },
        finishSchedule: {
            type: "array",
            items: DETAIL_SHEET_FINISH_SCHEDULE_META,
        },
        contractNotes: { type: "array", items: NOTE_LIST_META },
        clientSuccessCommunications: { type: "string" },
        writtenOrVerbalPromises: { type: "string" },
        requiredEquipmentList: {
            type: "array",
            items: DETAIL_SHEET_REQUIRED_EQUIPMENT_META,
        },
        challengingLocations: { type: "string" },
        requiredNotices: {
            type: "array",
            items: { type: "uuid", linkTo: "RequiredNoticeType" },
        },
        siteStorage: { type: "string" },
        liftParkingRequired: { type: "boolean" },
        liftOvernightParkingLocation: { type: "string" },
        portableToiletRequired: { type: "boolean" },
        portableToiletProposedLocation: { type: "string" },
        locationOfOnSiteWashrooms: { type: "string" },
        parking: { type: "uuid", linkTo: "ParkingType" },
        parkingNotes: { type: "string" },
        potentialDelays: { type: "string" },
        colourApprovalProcess: { type: "string" },
        permitsRequired: { type: "boolean" },
        permitsRequiredNote: { type: "string" },
        potentialBudgetChallenges: { type: "string" },
        description: { type: "array", items: HANDOFF_NOTE_DESCRIPTION_META },
        applicationInstructions: { type: "string" },
        clientSuccessGeneralNotes: { type: "string" },
        accessGeneralNotes: { type: "string" },
    },
    userFacingKey: "number",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
