import * as React from "react";
import { Table } from "react-bootstrap";
import { useProjectRecordQuery } from "../../clay/api";
import { longDate } from "../../clay/LocalDate";
import { FormWrapper, Optional } from "../../clay/widgets/FormField";
import { LinkSetWidget } from "../../clay/widgets/link-set-widget";
import { formatMoney } from "../estimate/TotalsWidget.widget";
import {
    calcQuotationExpectedContractValue,
    QUOTATION_META,
} from "../quotation/table";
import { TABLE_STYLE } from "../styles";
import { Project } from "./table";

export const SELECTED_QUOTATION_WIDGET = Optional(
    LinkSetWidget({
        meta: QUOTATION_META,
        name(record) {
            return (
                record.number.toString() +
                " - " +
                longDate(record.date) +
                " - " +
                formatMoney(calcQuotationExpectedContractValue(record))
            );
        },
    })
);

export function ProjectLostAwardShared(props: {
    data: Project;
    widgets: {
        competitors: any;
        selectedQuotations: any;
    };
}) {
    const quotations = useProjectRecordQuery(
        QUOTATION_META,
        props.data.id.uuid
    );

    const filteredQuotations = React.useMemo(() => {
        if (!quotations) {
            return [];
        }
        return quotations.filter((x) => !x.superceded && !x.change);
    }, [quotations]);

    return (
        <>
            <FormWrapper label="Proposals">
                <props.widgets.selectedQuotations
                    records={filteredQuotations}
                    readOnly={props.data.contractDetailsDate !== null}
                />
            </FormWrapper>
            <FormWrapper label="Competitors">
                <Table {...TABLE_STYLE}>
                    <thead>
                        {props.data.competitors.length > 0 && (
                            <tr>
                                <th />
                                <th style={{ width: "10em" }}>Bid Ranking</th>
                                <th>Competitor</th>
                                <th style={{ width: "10em" }}>Bid</th>
                                <th style={{ width: "10em" }}>
                                    % of Remdal's Bid
                                </th>
                                <th style={{ width: "10em" }}>
                                    Successful Bidder
                                </th>
                            </tr>
                        )}
                    </thead>
                    <props.widgets.competitors
                        containerClass="tbody"
                        addButtonText="Add Competitor"
                    />
                </Table>
            </FormWrapper>
        </>
    );
}
