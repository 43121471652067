import { find } from "lodash";
import { stringify } from "querystring";
import * as React from "react";
import { Button, Table } from "react-bootstrap";
import Modal from "react-modal";
import { Link } from "../../clay/link";
import { useQuickAllRecords } from "../../clay/quick-cache";
import { ClientCommunicationTemplate } from "../client-communication/table";
import {
    EmailContact,
    resolveTemplateText,
    TemplateData,
} from "../client-communication/variables";
import { emptyContactDetail } from "../contact/table";
import { User, USER_META } from "../user/table";
import { Project } from "./table";

function EmailContactRow(props: {
    contact: EmailContact;
    contacts: EmailContact[];
    setContacts: (contacts: EmailContact[]) => void;
}) {
    const onCheckChange = React.useCallback(
        (event) => {
            if (event.target.checked) {
                props.setContacts([...props.contacts, props.contact]);
            } else {
                props.setContacts(
                    props.contacts.filter((x) => x != props.contact)
                );
            }
        },
        [props.contacts, props.setContacts, props.contact]
    );
    return (
        <tr>
            <td>
                {props.contact.email && (
                    <input
                        type="checkbox"
                        style={{ width: "1em", height: "1em" }}
                        onChange={onCheckChange}
                        checked={props.contacts.indexOf(props.contact) !== -1}
                    />
                )}{" "}
                {props.contact.name}
            </td>
            <td>{props.contact.email}</td>
        </tr>
    );
}

export function launchEmail(props: {
    contacts: EmailContact[];
    template: ClientCommunicationTemplate;
    cc: string;
    project: Project;
    users: User[];
}) {
    const data: TemplateData = {
        project: props.project,
        users: props.users,
        contacts: props.contacts,
    };
    const href = `mailto:${props.contacts
        .map((contact) => contact.email)
        .join(";")}?${stringify({
        subject: resolveTemplateText(props.template.subject, data),
        body: resolveTemplateText(props.template.body, data),
        cc: props.cc,
    })}`;
    const emailWindow = window.open(href);
    // This magic incantation attempts to close the resulting popup window if it opened an external
    // program (Outlook)
    const checkClose = function () {
        try {
            // If I can access the href, it means the link ended opening an external program, we can remove it
            emailWindow?.location.href;
            return emailWindow?.close();
        } catch (error) {}
    };
    // check for it after 5 seconds
    let timer = setTimeout(checkClose, 5000);
    let checkLoaded: any = null;
    try {
        checkLoaded = function () {
            // once its loaded, reset the timer to 2 seconds
            clearTimeout(timer);
            timer = setTimeout(checkClose, 2000);
        };
        // Reset the time anytime something happens until it finishes
        emailWindow!.onload = checkLoaded;
        ["DomContentLoaded", "load", "beforeunload", "unload"].map((event) =>
            emailWindow?.addEventListener(event, checkLoaded!)
        );
    } catch (error) {
        // An error probably means we aren't allowed to access the information, because its an web based email client
        return checkLoaded!();
    }
}

function SendEmailPopup(props: {
    project: Project;
    template: ClientCommunicationTemplate;
    close: () => void;
    tag?: () => Promise<void>;
}) {
    const users = useQuickAllRecords(USER_META);
    const [contacts, setContacts] = React.useState<EmailContact[]>([]);
    const startEmail = React.useCallback(async () => {
        if (!users) {
            return;
        }
        launchEmail({
            contacts,
            template: props.template,
            cc: `project-${props.project.projectNumber}@dropsheet.remdal.com`,
            project: props.project,
            users,
        });

        props.close();
    }, [contacts, props.template, props.project, props.close]);

    const userContacts = React.useMemo(() => {
        const added = new Set<Link<User>>([]);
        const userContacts: EmailContact[] = [];
        for (const contact of props.project.billingContacts) {
            userContacts.push({
                ...contact,
                source: "billing",
            });
        }
        for (const contact of props.project.contacts) {
            userContacts.push({
                ...contact,
                source: "contact",
            });
        }

        for (const personnel of props.project.personnel) {
            if (!added.has(personnel.user)) {
                added.add(personnel.user);
                const user = find(
                    users,
                    (user) => user.id.uuid === personnel.user
                );
                if (user) {
                    userContacts.push({
                        ...emptyContactDetail(),
                        name: user.name,
                        email: user.accountEmail,
                        source: "remdal",
                    });
                }
            }
        }
        return userContacts;
    }, [props.project.personnel, users]);

    return (
        <Modal isOpen={true} onRequestClose={props.close}>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {userContacts.map((contact, index) => (
                        <EmailContactRow
                            contact={contact}
                            key={index}
                            contacts={contacts}
                            setContacts={setContacts}
                        />
                    ))}
                </tbody>
            </Table>
            <Button onClick={startEmail}>Generate Email</Button>
        </Modal>
    );
}

export function TemplateEmailPopupButton(props: {
    children: React.ReactNode;
    template: ClientCommunicationTemplate;
    project: Project;
}) {
    const [active, setActive] = React.useState(false);
    const closeEmailPopup = React.useCallback(
        () => setActive(false),
        [setActive]
    );

    return (
        <>
            {active && (
                <SendEmailPopup
                    project={props.project}
                    template={props.template}
                    close={closeEmailPopup}
                />
            )}
            <Button
                onClick={() => setActive(true)}
                style={{ marginRight: "20px" }}
            >
                {props.children}
            </Button>
        </>
    );
}
